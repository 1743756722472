<template>
  <div
    class="container"
    :style="{ backgroundImage: 'url('+picture+')', backgroundPosition: 'center 0', backgroundRepeat: 'no-repeat', backgroundColor: '#1d1e1e', backgroundSize: 'cover'}"
  >
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      picture: require('@/assets/bottom.png')
    }
  }
}
</script>

<style scoped>
@import '~@/styles/theme.css';

.container {
  width: 100%;
  height: 100%;
}
</style>
